<!-- 考试报名信息 -->
<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-12">
          <div class="card" style="min-height: 300px;">
            <div class="card-body">
              <h4 class="card-title pb-3"><span class="mr-2">近期考试工作日程</span>
                <span><a href=""><i class="iconfont icon-more head-more" id="more2"></i></a>
                  <b-tooltip target="more2" placement="bottom">更多</b-tooltip>
                </span>
              </h4>
              <div class="table-responsive border">
                <table class="table  light-table table-hover ">
                  <thead class="thead-light">
                    <tr>
                      <th>考试名称</th>
                      <!--  <th style="width: 8%;">考试日期</th>
                      <th style="width: 7%;">准考证打印</th> -->
                      <th style="width: 13%;" class="text-center">报名时间 </th>
                      <th style="width: 13%;" class="text-center">缴费时间 </th>
                      <th style="width: 13%;" class="text-center">审核时间 </th>
                      <th style="width: 12%;" class="text-center" v-if="isSuperAdmin">操作</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(obj,index) in tableList" :key="index">
                      <td>
                        <div class="font-blue" @click="getDetails(obj)">{{obj.ksmc}}</div>
                      </td>
                      <!-- <td>{{obj.kskssj}} </td>
                      <td>
                        {{obj.zkzdykssj}}
                        {{obj.zkzdyjssj}}
                      </td> -->
                      <td class="text-center">
                        <div>{{obj.bmkssj}}</div>
                        <div>{{obj.bmjssj}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{obj.jfkssj}} </div>
                        <div>{{obj.jfjssj}}</div>
                      </td>
                      <td class="text-center">
                        <div>{{obj.cskssj}}</div>
                        <div>{{obj.csjssj}}</div>
                      </td>
                      <td class="text-center" v-if="isSuperAdmin">
                        <el-button type="primary" size="mini" class="h30" @click="getDetails(obj)"><i
                            class="iconfont icon-come-l  mr-1"></i>进入</el-button>
                      </td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="float-right d-flex">
                <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
                  :current-page="pageData.pageNum" :page-size="pageData.pageSize"
                  layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
                </el-pagination>
              </div>

            </div>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
  import {
    getExamRecord,
    getExamDetails
  } from "@/api/admin/exam/examRecord.js"
  export default {
    props: {
      org: {
        default: false
      }
    },
    data() {
      return {
        pageData: {
          pageNum: 1,
          pageSize: 10,
          total: 0
        },
        tableList: [],
        isSuperAdmin: false
      };
    },
    methods: {
      // 获取考试数据列表
      getList() {
        getExamRecord(this.pageData).then(res => {
          if (res.status) {
            this.tableList = res.data
            this.pageData.total = res.total
          }
        })
      },
      // 去往考试详情
      getDetails(item) {
        getExamDetails(item.ksbmbh).then(res => {
          if (res.status) {
            if (!this.org) {
              window.location.href = "/admin/examIndex?ksbmbh=" + item.ksbmbh + "&sid=" + item.sid;
            } else {
              window.location.href = "/examIndex?ksbmbh=" + item.ksbmbh + "&sid=" + item.sid;
            }

          }
        })
      },
      getYhlb() {
        let userInfo = JSON.parse(this.getStore('userInfo'))
        if (userInfo.roleNum === 10) {
          this.isSuperAdmin = true
        } else {
          this.isSuperAdmin = false
        }

      },
      handleSizeChange(val) {
        this.pageData.pageSize = val
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val
        this.getList();
      },
    },
    mounted() {
      this.getList()
      this.getYhlb()
    }
  }
</script>

<style>
</style>
