<script>
  // 顶部和中间统计
  import {
    statisticalAll,
    refreshStatisticAll,
  } from "@/api/statistics/statistics.js"
  /**
   * Revenue Analytics component
   */
  export default {
    props: {
      org: {
        default: false
      }
    },
    data() {
      return {
        topData: {},
        totalCount: {},
        scoreCount: {},
        dayNum: {},
        weekNum: {},
        monthNum: {},
        dataBox: {},
        lastYearCount: {},
        scope: "numOfMonth",
        // 报名趋势
        series2: [{
            name: new Date().getFullYear(),
            type: "column",
            data: []
          },

        ],
        componentKey: 1,
        chartOptions2: {
          chart: {
            height: 280,
            type: "line",
            toolbar: {
              show: false
            }
          },
          stroke: {
            width: [0, 3],
            curve: "smooth"
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "30%"
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          colors: ["#5664d2", "#1cbb8c"],
          labels: [],
          // labels: [
          //   "1:00",
          //   "2:00",
          //   "3:00",
          //   "4:00",
          //   "5:00",
          //   "6:00",
          //   "7:00",
          //   "3:00",
          //   "9:00",
          //   "10:00",
          //   "11:00",
          //   "12:00",
          //   "13:00",
          //   "14:00",
          //   "15:00",
          //   "16:00",
          //   "17:00",
          //   "18:00",
          //   "19:00",
          //   "20:00",
          //   "21:00",
          //   "22:00",
          //   "23:00",
          //   "24:00"
          // ]
        }
      };
    },
    computed: {
      topdd() {
        return [{
            title: "注册用户",
            icon: " icon-user-group",
            value: this.totalCount.zcyh || 0,
            text: "今年新注册用户" + (this.topData.zcyh || 0) + "人"
          },
          {
            title: "报考数据",
            icon: "icon-stLine-doc-edit-l",
            value: this.totalCount.bmrs || 0,
            text: "今年报考" + (this.topData.bmrs || 0) + "人次" +
              (!this.lastYearCount.bmrs || this.lastYearCount.bmrs == 0 ? "" : "，较上年度" + ((this.topData.bmrs / this
                .lastYearCount.bmrs) * 100).toFixed(2) + "%")
          },
          {
            title: "成绩数据",
            icon: "icon-newspaper-o",
            value: this.scoreCount.count || 0,
            text: "今年新增" + (this.scoreCount.new || 0) + "条" + (!this.scoreCount.lastYear || this.scoreCount
              .lastYear == 0 ? "" : "，较上年度" + ((this.scoreCount.new / this.scoreCount.lastYear) * 100).toFixed(2) + "%")

          }
        ]
      }

    },
    methods: {

      // 切换统计类型
      chooseTrend(type) {
        this.scope = type
        this.series2[0].data = this.dataBox[type];
        this.componentKey += 1;
      },
      // 统计数据
      getAllStatistic() {
        statisticalAll().then(res => {
          if (res.status) {
            if (res.data) {
              this.topData = res.data.totalMap ? res.data.totalMap : {} //今年的
              this.dayNum = res.data.numOfDay ? res.data.numOfDay : {}
              this.monthNum = res.data.numOfMonth ? res.data.numOfMonth : {}
              this.weekNum = res.data.numOfWeek ? res.data.numOfWeek : {},
                this.totalCount = res.data.countTotalMap ? res.data.countTotalMap : {}, //总的
                this.scoreCount = res.data.countAndNew ? res.data.countAndNew : {},
                this.lastYearCount = res.data.lastYearTotalMap ? res.data.lastYearTotalMap : {} //去年的
              let box = ['numOfDay', 'numOfMonth', 'numOfWeek'];
              let need = {}
              box.forEach(k => {
                let main = res.data[k]
                need[k] = []
                if (main) {
                  // for (let i = 2; i < 24; i = i + 2) {//每隔两小时
                  for (let i = 0; i < 24; i++) {
                    need[k].push(main["query" + i])
                    this.chartOptions2.labels.push(i + ":00")
                  }
                }

              })
              this.dataBox = need
              this.$nextTick(() => {
                this.chooseTrend("numOfMonth")
              })
            }
          }

        })
      },
      refreshData() { //刷新统计数据
        let ksnf = new Date().getFullYear()
        this.$confirm('更新将会占用大量系统资源，请勿在考试报名期间更新，是否确认更新（更新期间请耐心等待）？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          refreshStatisticAll({
            ksnf: ksnf
          }).then(res => {
            if (res.status) {
              this.$message({
                type: 'success',
                message: '数据刷新成功!'
              });
              this.getAllStatistic()
            }
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消刷新'
          });
        });

      },


    },
    mounted() {
      this.getAllStatistic()

    },
  };
</script>

<template>
  <div class="col-xl-8">
    <div class="row">
      <div class="col-md-4" v-for="(data, index) in topdd" :key="index">
        <div class="card">
          <div class="card-body">
            <div class="media">
              <div class="media-body overflow-hidden">
                <p class="text-truncate font-size-14 mb-2">{{data.title}}</p>
                <h4 class="mb-0">{{data.value}}</h4>
              </div>
              <div class="text-info">
                <i :class="`${data.icon} font-size-24 iconfont`"></i>
              </div>
            </div>
          </div>

          <div class="card-body border-top py-3">
            <div class="text-truncate">
              <span class="text-muted  font-size-12">{{data.text}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="float-right d-none d-md-inline-block">
          <div class="btn-group mb-2">
            <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfWeek'?'active':'']"
              @click="chooseTrend('numOfWeek')">本周</button>
            <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfDay'?'active':'']"
              @click="chooseTrend('numOfDay')">今天</button>
            <button type="button" class="btn btn-sm btn-light" :class="[this.scope=='numOfMonth'?'active':'']"
              @click="chooseTrend('numOfMonth')">本月</button>
          </div>
        </div>
        <h4 class="card-title mb-4">报名趋势 <el-button type="primary" plain size="mini" class="h30 ml-2"
            @click="refreshData">刷新统计数据
          </el-button>
        </h4>

        <div>
          <div id="line-column-chart" class="apex-charts" dir="ltr"></div>
          <apexchart class="apex-charts" height="280" dir="ltr" :series="series2" :key="componentKey"
            :options="chartOptions2">
          </apexchart>
        </div>
      </div>

      <div class="card-body border-top text-center">
        <div class="row">
          <div class="col-sm-3">
            <div class="d-inline-flex">
              <h5 class="mr-2">{{dayNum.allDay||0}}</h5>
            </div>
            <p class="text-muted text-truncate mb-0">今日报名

            </p>
          </div>
          <div class="col-sm-3">
            <div class="d-inline-flex">
              <h5 class="mr-2">{{weekNum.allDay||0}}</h5>
            </div>
            <p class="text-muted text-truncate mb-0">本周报名

            </p>
          </div>
          <div class="col-sm-3">
            <div class="d-inline-flex">
              <h5 class="mr-2">{{monthNum.allDay||0}}</h5>
            </div>
            <p class="text-muted text-truncate mb-0">本月报名

            </p>
          </div>
          <div class="col-sm-3">
            <div class="d-inline-flex">
              <h5 class="mr-2">{{topData.bmrs||0}}</h5>
            </div>
            <p class="text-muted text-truncate mb-0">全部报名

            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
