<style type="text/css">
  @import "~@/assets/css/common.css";
  @import "~@/assets/css/table.css";
</style>
<style type="text/css" scoped>
  .table td {
    line-height: 20px;
  }

  .font-blue {
    cursor: pointer;
  }

  /deep/ .el-button--mini {
    padding: 7px 6px;
  }

</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import message from "@/views/pages/admin/admin-index/message";
  import ExamTable from "@/views/pages/admin/admin-index/schedule-table";
  import Analytics from "@/views/pages/admin/admin-index/analytics";
  import Revenue from "@/views/pages/admin/admin-index/revenue"



  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
      message,
      ExamTable,
      Analytics,
      Revenue
    },
    data() {
      return {
        title: "首页",
        items: [{
            text: "用户首页",
            href: "/admin"

          },
          {
            text: "首页",
            active: true
          }
        ],

      };
    },


  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <Revenue></Revenue>
      <div class="col-xl-4">
        <Analytics></Analytics>
        <message></message>
      </div>
    </div>
    <ExamTable></ExamTable>
  </Layout>
</template>
