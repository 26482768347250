<script>
  import {
    getRegisting
  } from "@/api/statistics/statistics.js"
  /**
   * Sales Analytics component
   */
  export default {
    props: {
      org: {
        default: false
      }
    },
    data() {
      return {
        openData: [],
        openObj: {},
        series: [42, 26, 15],
        chartOptions: {
          chart: {
            height: 230,
            type: "donut"
          },
          labels: ["已缴费", "待审核", "已审核", "总报名"],
          plotOptions: {
            pie: {
              donut: {
                size: "75%"
              }
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: {
            show: false
          },
          colors: ["#1cbb8c", "#5664d2", "#fcb92c", "#10749f"]
        },
      };
    },
    methods: {
      // 开放报名统计
      getOpen() {
        getRegisting().then(res => {
          if (res.status) {
            if (res.data) {
              this.openData = res.data.statisticalList ? res.data.statisticalList : {}
              let jfrs = 0,
                dshrs = 0,
                yshrs = 0,
                bmrs = 0
              for (let i = 0; i < this.openData.length; i++) {
                jfrs += this.openData[i].jfrs
                // dshrs += this.openData[i].dshrs
                yshrs += this.openData[i].yshrs
                bmrs += this.openData[i].bmrs
              }
              this.openObj["jfrs"] = jfrs
              this.openObj["dshrs"] = bmrs - yshrs
              this.openObj["yshrs"] = yshrs
              this.openObj["bmrs"] = bmrs
              this.series = []
              this.series.push(jfrs, dshrs, yshrs, bmrs)
            }
          }
        })
      },
    },
    mounted() {
      this.getOpen()
    },
  };
</script>

<template>
  <div class="card">
    <div class="card-body">
      <!-- <div class="float-right">
        <select class="custom-select custom-select-sm">
          <option selected>已缴费</option>
          <option value="1">待审核</option>
          <option value="2">未缴费</option>
          <option value="3">总报名</option>
        </select>
      </div> -->
      <h4 class="card-title mb-4">当前开放报名 {{openData.length}}项<span><a href=""><i class="iconfont icon-more head-more"
              id="more"></i></a>
          <b-tooltip target="more" placement="bottom">更多</b-tooltip>
        </span></h4>

      <div id="donut-chart" class="apex-charts"></div>
      <apexchart class="apex-charts" height="230" dir="ltr" :series="series" :options="chartOptions"></apexchart>
      <div class="row">
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-success  font-size-10 mr-1"></i> 已缴费
            </p>
            <h5 class="font-size-12">{{openObj.jfrs||0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-primary  font-size-10 mr-1"></i> 待审核
            </p>
            <h5 class="font-size-12">{{openObj.dshrs||0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-warning font-size-10 mr-1"></i> 已审核
            </p>
            <h5 class="font-size-12">{{openObj.yshrs||0}}</h5>
          </div>
        </div>
        <div class="col-3">
          <div class="text-center mt-4">
            <p class="mb-2 text-truncate">
              <i class="mdi mdi-circle text-dark font-size-10 mr-1"></i> 总报名
            </p>
            <h5 class="font-size-12">{{openObj.bmrs||0}}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
