<script>
import {getWebNoticePage} from '@/api/admin/notice/notice.js'
/**
 * message component
 */
export default {
  components: {

  },
  props:{
    org: {
      default: false
    }
  },
  data() {
    return {
      noticeList: [],
      pageData: {nowPage: 1, pageSize: 2, sfyd: false},
    };
  },
  methods: {
    // 获取数据列表
    getList() {
      getWebNoticePage(this.pageData).then(res => {
        if (res.status) {
          this.noticeList = res.data
        }
      })
    },
  },
  mounted() {
    this.getList()
  }
};


</script>

<template>
  <div class="card" style="height: 248px;">
    <div class="card-body">
      <h4 class="card-title pb-3 border-dash">系统消息
      <span><a :href="org?'/list':'/admin/list'"><i class="iconfont icon-more head-more" id="more1"></i></a>
        <b-tooltip target="more1" placement="bottom">更多</b-tooltip>
      </span></h4>
      <div class="mb-3" >
        <a href="javascript:;" @click="$yzNotice().show(n.noticeId)" v-for="(n, v) in noticeList" :key="v">
          <div class="d-flex border-dash p-2">
          <img src="@/assets/images/person/stLine-talk-l.png" class="sys-img mr-2" alt="">
          <div>
            <div class=" line1 text-dark">{{n.noticeTitle}}</div>
            <div class="grey-time">{{n.createTime}}</div>
          </div>
        </div>
        </a>
      </div>
      <div>
        <a :href="org?'/list':'/admin/list'" class="blue-font "><div class="text-center font14 h35"><i class="arrow-down mr-2"></i>查看更多</div></a>
      </div>
    </div>
  </div>
</template>